import Stack from "@mui/material/Stack";
import styles from "./index.module.scss";
import footerIcon from "../../assets/images/logo.png";
import insurerFriendlyLogo from "../../assets/images/LandingPage/insurer-friendly.jpeg";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import pxToRem from "../../helpers/pxToRem";
import useGetScreenType from "../../hooks/useGetScreenType";
import { COVER_YOU_ADDRESS, LANDING_PAGE_PARAMS, ROUTE_PARAMS, } from "../../routers/shared";
import { useNavigate } from "react-router-dom";
import ApplyNowModal from "../CustomizedModal/applyNowModal";
import { useState } from "react";

const Footer = () => {
  const navigate = useNavigate();
  const screenType = useGetScreenType();
  const [isApplyNowModal, setIsApplyNowModal] = useState(false);
  return (
    <Stack className={styles.footer}>
        <ApplyNowModal isApplyNowModal={isApplyNowModal} handleClose={() => setIsApplyNowModal(false)} />
        <Stack className={styles.wrapper}>
            <Stack className={styles.leftStack}>
                <img src={footerIcon} alt='Coveryou' className={styles.coveryou} />
                <p className='neutrals500'> We'll arrange a hire car at no cost to you. We'll provide a similar car to your own, so you can keep going while your vehicle is being repaired. </p>
                <p className='neutrals500'>
                    <a className={styles.linkText} href="mailto:help@Coveryou.com.au">help@Coveryou.com.au</a>
                    <a className={styles.linkText} href="tel:+1300505508"><span className={styles.mobile}>1300 505 508</span></a> </p>
                <Stack className={styles.socialLinksStack}>
                    <a href="https://www.linkedin.com/company/coveryou-services">
                        <LinkedInIcon className={styles.socialIcon} style={{ color: "#01639d" }} /> 
                    </a>
                    <img src={insurerFriendlyLogo} alt='Coveryou' className={styles.insurerFriendly} />
                </Stack>
            </Stack>
            <Stack className={styles.rightStack}>
                <Stack className={styles.linksStack}>
                    <a href={`/${ROUTE_PARAMS.CONTACT}`} className={styles.link} onClick={(e) => { e.preventDefault(); navigate(`/${ROUTE_PARAMS.CONTACT}`); }} > <p>How it works</p> </a>
                    <a href={`/${ROUTE_PARAMS.CONTACT}`} className={styles.link} onClick={(e) => { e.preventDefault(); navigate(`/${ROUTE_PARAMS.CONTACT}`); }} > <p>For Insurers and Repairers</p> </a>
                    <a href={`/${ROUTE_PARAMS.CONTACT}`} className={styles.link} onClick={(e) => { e.preventDefault(); navigate(`/${ROUTE_PARAMS.CONTACT}`); }} > <p>Contact</p> </a>
                    <a className={styles.link} href={`/${ROUTE_PARAMS.CONTACT}`} onClick={(e) => { e.preventDefault(); navigate(`/${ROUTE_PARAMS.CONTACT}`); }} > <p>Arrange a hire car in Melbourne</p> </a>
                    <a className={styles.link} href={`/${ROUTE_PARAMS.CONTACT}`} onClick={(e) => { e.preventDefault(); navigate(`/${ROUTE_PARAMS.CONTACT}`); }} > <p>Arrange a hire car in Adelaide</p> </a>
                    <a className={styles.link} href={`/${ROUTE_PARAMS.CONTACT}`} onClick={(e) => { e.preventDefault(); navigate(`/${ROUTE_PARAMS.CONTACT}`); }} > <p>Arrange a hire car in Sydney</p> </a>
                    <p className='neutrals500' style={{ marginTop: pxToRem(16), display: screenType === "PC" ? "unset" : "none", }} > © 2024 Coveryou All rights reserved. </p>
                </Stack>
                <Stack className={styles.linksStack}>
                    <a className={styles.link} href={COVER_YOU_ADDRESS} onClick={(e) => { e.preventDefault(); window.open(COVER_YOU_ADDRESS, "_self"); }} > <p>About us</p> </a>
                    <a className={styles.link} href={`/${LANDING_PAGE_PARAMS.FAQ}`} onClick={(e) => { e.preventDefault(); navigate(`/${LANDING_PAGE_PARAMS.FAQ}`); }} > <p>FAQ</p> </a>
                    <a className={styles.link} href={`http://corporate.coveryou.com.au/`}> <p>Coveryou Corporate</p> </a>
                    <a className={styles.link} href={`/${ROUTE_PARAMS.CONTACT}`} onClick={(e) => { e.preventDefault(); navigate(`/${ROUTE_PARAMS.CONTACT}`); }} > <p>Arrange a hire car in Brisbane</p> </a>
                    <a className={styles.link} href={`/${ROUTE_PARAMS.CONTACT}`} onClick={(e) => { e.preventDefault(); navigate(`/${ROUTE_PARAMS.CONTACT}`); }} > <p>Arrange a hire car in Gold Coast</p> </a>
                    <a className={styles.link} style={{ width: 0, visibility:'hidden' }} href="#">&nbsp;</a>
                    <Stack className={styles.policiesRow} style={{ marginTop: pxToRem(16) }} >
                        <a href={`/${ROUTE_PARAMS.PRIVACY_POLICIES}`} className={styles.link} onClick={(e) => { e.preventDefault(); navigate(`/${ROUTE_PARAMS.PRIVACY_POLICIES}`); }} > <p>Privacy Policy</p> </a>
                        <a href={`/${ROUTE_PARAMS.TERMS_OF_USE}`} className={styles.link} onClick={(e) => { e.preventDefault(); navigate(`/${ROUTE_PARAMS.TERMS_OF_USE}`); }} > <p>Terms of Use</p> </a>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
        <Stack className={styles.policyStack}>
            <p className='neutrals500'>© 2024 Coveryou All rights reserved.</p>
            <a href={`/${ROUTE_PARAMS.PRIVACY_POLICIES}`} className={styles.link} onClick={(e) => { e.preventDefault(); navigate(`/${ROUTE_PARAMS.PRIVACY_POLICIES}`); }} > <p>Privacy Policy</p> </a>
            <a href={`/${ROUTE_PARAMS.TERMS_OF_USE}`} className={styles.link} onClick={(e) => { e.preventDefault(); navigate(`/${ROUTE_PARAMS.TERMS_OF_USE}`); }} > <p>Terms of Use</p> </a>
        </Stack>
    </Stack>
  );
};

export default Footer;
